import { _, React, Component, className, lib } from 'components'; //eslint-disable-line
import './cart-page.scss';
import CartProductInstancePopup from 'components/cart-product-instance-popup/cart-product-instance-popup';
import { removeCartProductInstance, updateContactInfo, updateDeliveryAddress, resetCartOnCheckout } from 'reducers/cart/cart-reducer';
import { Link } from 'react-router-dom';
import { Form } from 'henrybuilt-react-library';
import { CheckoutForm } from '@henrybuilt/react-checkout/src';
import PriceDisplay from 'components/price-display/price-display';
import utilities from 'utility/utility-functions';
import localStorage from 'local-storage';
import queryString from 'query-string';
import moment from 'moment';
import { connect } from 'reducers/helpers';

var allowLocalPickup = localStorage('allowLocalPickup') === '1' || _.get(queryString.parse(window.location.search), 'allowLocalPickup') === '1';

class CartPage extends Component {
  state = {
    isEditing: false,
    activeProductInstance: null, // {}
    isLoading: true,
    products: null, // {}
    cartIsEmpty: false,
    taxData: null,
    showThankYouMessage: false,
    deliveryType: 'inside'
  }

  componentDidMount() {
    document.title = 'cart';

    var user = _.get(this.props, 'session.user');

    var {contactInfo} = this.props.cart;

    var shouldDeliveryAddressUpdate = false;

    if (user) {
      _.forEach(['name', 'phone', 'email'], contactDetailKey => {
        if (!contactInfo[contactDetailKey]) {
          contactInfo[contactDetailKey] = user[contactDetailKey];

          shouldDeliveryAddressUpdate = true;
        }
      });
    }

    if (shouldDeliveryAddressUpdate) this.props.updateDeliveryAddress({contactInfo});

    this.getData();
  }

  getData = async () => {
    var canViewTradePrice =  _.get(this.props.session, 'user.id') !== undefined;
    var productInstances = _.get(this.props.cart, 'productInstances');

    if (this.deliveryAddressValid) await this.updateTaxRate();

    if (!_.isEmpty(productInstances)) {
      var {products} = await utilities.getFullProductsData({ids: _.map(productInstances, 'productId'), canViewTradePrice});

      this.setState({products, isLoading: false});
    }
    else {
      this.setState({cartIsEmpty: true, isLoading: false});
    }
  }

  async updateTaxRate() {
    var {deliveryAddress} = this.props.cart;

    try {
      var taxDataResponse = await lib.api.request({uri: 'tax-rate-for-address', body: {address: {...deliveryAddress, country: 'US'}, companyKey: 'hb'}, useActualErrorMessages: true});

      this.setState({taxData: taxDataResponse.data});
    }
    catch (error) {
      this.setState({taxData: null});
    }
  }

  checkout = async () => {
    if (!this.state.isProceedingToCheckout) {
      this.setState({isProceedingToCheckout: true});

      var {deliveryAddress, contactInfo, productInstances} = this.props.cart;
      var {deliveryType} = this.state;

      if(this.deliveryAddressValid && this.contactInfoValid) {
        var {taxData} = this.state;

        if (!taxData) {
          alert('We were unable to calculate tax data for your address. Please confirm your delivery address.');
        }
        else {
          if (!_.isEmpty(productInstances)) {
            try {
              var response = await lib.api.request({uri: 'po-cart/create-order', body: {deliveryAddress, contactInfo, productInstances, deliveryType}});

              localStorage('transactionToken', response.data.transactionToken);

              window.scrollTo(0, 0);
            }
            catch {
              alert(`Sorry, there was an error creating your order`);
            }
          }
          else {
            alert(`There must be items in cart to checkout`);
          }
        }
      }
      else {
        alert('Please complete all delivery and contact fields');
      }

      this.setState({isProceedingToCheckout: false});
    }
  }

  handleTransactionFullyPaid = () => {
    window.scrollTo(0, 0);

    this.props.resetCartOnCheckout();

    this.setState({showThankYouMessage: true});
  }

  handleChangeForContactInfoInput = ({key, value}) => {
    if (_.includes(['phone'], key) && value) value = parseFloat(value);

    this.props.updateContactInfo({contactInfo: {[key]: value}});
  }

  handleChangeForDeliveryAddressInput = async ({key, value}) => {
    if (_.includes(['zip'], key) && value) value = parseFloat(value);

    this.props.updateDeliveryAddress({deliveryAddress: {[key]: value}});

    if (this.deliveryAddressValid) await this.updateTaxRate();
  }

  setDeliveryType = ({value}) => this.setState({deliveryType: value});

  editProductInstance = ({productInstance}) => this.setState({isEditing: true, activeProductInstance: productInstance});

  removeProductInstance = ({productInstance}) => {
    this.props.removeCartProductInstance({productInstance});

    if (_.isEmpty(this.props.cart.productInstances)) this.setState({cartIsEmpty: true});
  }

  doneEditingProductInstance = () => this.setState({isEditing: false, activeProductInstance: null});

  cancelOrder = () => {
    window.scrollTo(0, 0);

    var transactionToken = localStorage('transactionToken');

    if (transactionToken) lib.api.request({uri: 'po-cart/cancel-order', body: {transactionToken}});

    localStorage.remove('transactionToken');

    this.setState({isLoading: false});
  }

  get deliveryAddressValid() {
    var {deliveryAddress} = this.props.cart;

    return _.every(['streetAddress', 'city', 'state', 'zip'], key => deliveryAddress[key]);
  }

  get contactInfoValid() {
    var {contactInfo} = this.props.cart;

    return _.every(['name', 'phone', 'email'], key => contactInfo[key]);
  }

  get cartPriceData() {
    var {deliveryAddress, productInstances} = this.props.cart;
    var {products, taxData, deliveryType} = this.state;
    var {user} = this.props.session;
    //var shippingText, taxText, productText, totalText;

    if (!_.isEmpty(productInstances) && !_.isEmpty(products)) {
      var {products, productInstances, materialClasses, pricingRules} = utilities.formatProductsForPricing({products, productInstances}); //eslint-disable-line

      var project = {
        productInstances,
        discount: 0,
        companyKey: 'hb',
        brandKey: 'po',
        deliveryState: _.upperCase(_.get(deliveryAddress, 'state')),
        effectivePricingDate: moment(),
        taxRate: _.get(taxData, 'taxRate', 0),
        deliveryType
      };

      var cartPriceData = lib.price.priceDataFor('project', project, {pricingRules, products, materialClasses}, {isClientOrPartner: _.get(user, 'id') !== undefined});
    }

    return cartPriceData;
  }

  get productsPriceText() {
    var {cartPriceData} = this;

    var productsDinero = _.get(cartPriceData, 'subtotals.product');

    return productsDinero ? productsDinero.toFormat('$0,0.00') : '...';
  }

  get taxText() {
    var {cartPriceData} = this;

    var productsDinero = _.get(cartPriceData, 'subtotals.tax');

    return productsDinero && productsDinero.getAmount() !== 0 ? productsDinero.toFormat('$0,0.00') : 'N/A';
  }

  get shippingPriceText() {
    var {cartPriceData} = this;

    var productsDinero = _.get(cartPriceData, 'subtotals.shipping');

    return productsDinero ? productsDinero.toFormat('$0,0.00') : '...';
  }

  get totalPriceText() {
    var {cartPriceData} = this;

    var productsDinero = _.get(cartPriceData, 'total');

    return productsDinero ? productsDinero.toFormat('$0,0.00') : '...';
  }

  get transactionToken() {
    return localStorage('transactionToken');
  }

  render() {
    var {cart, session} = this.props;
    var {contactInfo, deliveryAddress, productInstances} = cart;
    var {removeProductInstance, editProductInstance, doneEditingProductInstance, setDeliveryType, transactionToken} = this;
    var {activeProductInstance, products, isLoading, isEditing, cartIsEmpty, showThankYouMessage, deliveryType} = this.state;

    var contiguousStatesArray = _.filter(lib.formData.states, state => !_.includes(['HI', 'AK'], state));

    var deliveryTypeOptions = [{value: 'inside', title: 'inside'}, {value: 'curbside', title: 'curbside/dockside'}];

    if (allowLocalPickup) deliveryTypeOptions.push({value: 'pickup', title: 'pickup'});

    return (
      !isLoading ? (
        !cartIsEmpty ? (
          <div className='cart-page'>
            {showThankYouMessage ? (
              <div className='order-completed'>
                thank you for your order<br/>
                <br/>
                we sent you an email receipt and will reach out shortly to confirm details<br/>
                <br/>
                <Link to='/objects'>keep shopping</Link>
              </div>
            ) : transactionToken ? (
              <div className='checkout-content'>
                <div className='cancel-button-wrapper back-arrow-link'>
                  <img onClick={this.cancelOrder} alt='back' className='back-arrow' src={utilities.leftArrowUrl}/>
                </div>
                <div className='total-display'>{this.totalPriceText}</div>
                <div className='explanation'>After payment, a member of our team will contact you to review the details of your order with you. If you would like to review the details of your order now, please email <a target="_blank" rel="noopener noreferrer" href='mailto:objects@henrybuilt.com'>objects@henrybuilt.com</a>.
                <br/><br/>
                A member of our logistics team will contact you to make arrangements for shipping and delivery as your order completes production.
                To learn more, read our <a target="_blank" rel="noopener noreferrer" href='https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/po-website/po-terms.pdf'>Terms & Conditions.</a></div>
                <CheckoutForm
                  transactionToken={transactionToken}
                  paymentMethodKeys={['electronicCheck', 'creditCard']}
                  handleTransactionFullyPaid={this.handleTransactionFullyPaid}
                />
              </div>
            ) : (
              <div className='items-content'>
                {isEditing && (
                  <CartProductInstancePopup
                    onClose={doneEditingProductInstance}
                    productInstance={activeProductInstance}
                    product={_.find(products, {id: activeProductInstance.productId})}
                  />
                )}
                <div className='left-content'>
                  <Link to='/objects' className='back-arrow-link'>
                    <img alt='back' className='back-arrow' src={utilities.leftArrowUrl}/>
                  </Link>
                  <div className='product-instances'>
                    {!_.isEmpty(productInstances) ?
                      _.map(productInstances, productInstance => (
                        <CartProductInstance {...{productInstance, product: _.find(products, {id: productInstance.productId}), editProductInstance, removeProductInstance, key: productInstance.uuid, user: _.get(session, 'user')}}/>
                      ))
                      :
                      <div/>
                    }
                  </div>
                  <div className='order-notes'>
                    <div className='leadtime-order-note'>
                    * Your Primary Objects order will be ready to ship within 20 weeks of receipt of your payment.
                    </div>
                    <div className='review-order-note'>
                      * If you would like to review your order with someone from our team, or your project is in Alaska, Hawaii, or outside the U.S., please email <a target="_blank" rel="noopener noreferrer" href='mailto:objects@henrybuilt.com'>objects@henrybuilt.com</a>.
                    </div>
                  </div>
                </div>
                <div className='order-form-container'>
                  <Form
                    useSubmitInput
                    on={{submit: this.checkout}}
                    submitText={`proceed to checkout`}
                    isSubmittingText={`preparing transaction`}
                  >
                    <div {...className(['contact-info', 'form-section'])}>
                      <div className='form-section-title'>contact info</div>
                      {_.map(['name', 'phone', 'email'], contactKey => (
                        <Form.TextInput
                          key={contactKey}
                          placeholder={contactKey}
                          value={contactInfo[contactKey]}
                          on={{change: ({value}) => this.handleChangeForContactInfoInput({key: contactKey, value})}}
                        />
                      ))}
                    </div>
                    <div {...className(['delivery-info', 'form-section'])}>
                      <div className='form-section-title'>delivery address *</div>
                      <div className='delivery-address-inputs'>
                        {_.map(['streetAddress', 'city', 'zip', 'state'], addressKey => (
                          <div key={addressKey} className={`${_.kebabCase(addressKey)}-input`}>
                            {addressKey !== 'state' ? (
                              <Form.TextInput
                                placeholder={addressKey === 'streetAddress' ? 'street address' : addressKey}
                                value={deliveryAddress[addressKey]}
                                on={{change: ({value}) => this.handleChangeForDeliveryAddressInput({key: addressKey, value})}}
                              />
                            ) : (
                              <Form.DropdownInput
                                autofill='state'
                                name='state'
                                className='state-input'
                                placeholder='state'
                                value={deliveryAddress.state}
                                on={{change: ({value}) => this.handleChangeForDeliveryAddressInput({key: addressKey, value})}}
                                options={_.map(contiguousStatesArray, state => ({title: state, value: state}))}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div {...className(['delivery-type', 'form-section'])}>
                      <div {...className(['form-section-title', 'delivery-type'])}>delivery type&nbsp;&nbsp;
                        <a target="_blank" rel="noopener noreferrer" href='https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/po-website/po-terms.pdf'>(?)</a>
                      </div>
                      <Form.RadioInput
                        value={deliveryType}
                        on={{change: setDeliveryType}}
                        options={deliveryTypeOptions}
                      />
                    </div>
                    <div className='pricing-info'>
                      <div className='subtotal-line-item'>
                      <div className='subtotal-line-label'>products:</div>
                        <div className='subtotal-line-content'>{this.productsPriceText}</div>
                      </div>
                      <div className='subtotal-line-item'>
                        <div className='subtotal-line-label'>shipping:</div>
                        <div className='subtotal-line-content'>{this.deliveryAddressValid ? this.shippingPriceText : 'enter delivery info'}</div>
                      </div>
                      <div className='subtotal-line-item'>
                        <div className='subtotal-line-label'>tax:</div>
                        <div className='subtotal-line-content'>{this.deliveryAddressValid ? this.taxText : 'enter delivery info'}</div>
                      </div>
                      <div className='subtotal-line-item'>
                        <div className='subtotal-line-label'>total:</div>
                        <div className='subtotal-line-content'>{this.totalPriceText}</div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className='empty-cart'>
            <div>your cart is empty</div>
            <br/>
            <Link to='/objects' className='keep-shopping'>keep shopping</Link>
          </div>
        )
      ) : (
        <div className='loading-symbol'/>
      )
    );
  }
}

export default connect({mapState: ['cart', 'session'], mapDispatch: {removeCartProductInstance, updateDeliveryAddress, updateContactInfo, resetCartOnCheckout}})(CartPage);

var CartProductInstance = ({productInstance, editProductInstance, removeProductInstance, product, user}) => {
  var primaryImage = _.find(product.media, {type: 'vimage', subjectType: 'standard'}) || _.find(product.media, {type: 'image', subjectType: 'standard'});

  var primaryImageUrl = _.get(primaryImage, 'url');

  var detailNamesMap = {pulls: 'pull type', pullMaterials: 'pull material', productDetails: 'other options'};

  return (
    <div className='product-instance'>
      <Link
        onClick={() => window.scrollTo(0, 0)}
        to={`/objects/${product.id}?cameFrom=cart`}
        className='product-instance-thumbnail'
        style={{backgroundImage: `url(${primaryImageUrl})`}}
      ></Link>
      <div className='product-options-container'>
        <div className='left-container'>
          <div className='product-header'>
            <Link className='product-title' to={`/objects/${product.id}?cameFrom=cart`} onClick={() => window.scrollTo(0, 0)}>{product.title}</Link>
          </div>
          <div className='details-display'>
            {_.map(['quantity', 'dimensions', 'materials', 'pulls', 'pullMaterials', 'productDetails'], detailType => {
              var detailExists = _.isNumber(productInstance[detailType]) || !_.isEmpty(productInstance[detailType]);

              if (detailType === 'pullMaterials') detailExists = detailExists && _.get(productInstance.pulls, 'pull.materialClass.id') !== 1;

              return (
                detailExists && <div {...className(['detail-display', `${detailType}-detail`])} key={detailType}>
                  <div className='detail-type-title'>{_.startCase(detailNamesMap[detailType] ? detailNamesMap[detailType] : detailType)}:</div>
                  <div className='details-content'>
                    {_.isObject(productInstance[detailType]) ? _.map(productInstance[detailType], (value, key) => {
                      var productCategoryChoices = product[detailType === 'materials' ? 'materialAssociations' : detailType];

                      var detailTitle;

                      if (detailType === 'dimensions') {
                        detailTitle = _.includes(['width', 'height', 'depth'], key) ? key[0] : key;
                      }
                      else if (!_.isEmpty(productCategoryChoices) && !_.includes(detailType, 'pull')) {
                        detailTitle = _.get(_.find(productCategoryChoices, category => category.key === key || category.id === parseInt(key)), 'title');
                      }
                      else  {
                        detailTitle = null;
                      }

                      return (
                        value && !_.includes(['title', 'key', 'standardSize'], key) && <div className='detail' key={key}>
                          {detailTitle && <div className='detail-title'>{detailTitle}:</div>}
                          <div className='detail-value'>
                            {_.isObject(value) ? value.title || _.get(value, 'fixed[0]') : value}{detailType === 'dimensions' && `"`}
                          </div>
                        </div>
                      );
                    }) : (
                      <div className='detail-value'>{productInstance[detailType]}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className='right-container'>
          <div className='price-and-editing-container'>
            <PriceDisplay  {...{productInstance, product, user}}/>
            <div className='product-instance-buttons'>
              <div className='edit-button' onClick={() => editProductInstance({productInstance})}>edit</div>
              <div className='remove-button' onClick={() => removeProductInstance({productInstance})}>remove</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
