import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import ProductsHeader from './products-header/products-header';
import ProductsIndex from './products-index/products-index';
import ProductsShow from './products-show/products-show';
import Sidebar from 'components/sidebar/sidebar';
import PageNotFound from 'components/pages/page-not-found/page-not-found-page';
import utilities from 'utility/utility-functions';
import {connect} from '@henrybuilt/react-app/src';
import queryString from 'query-string';
import './products-page.scss';

class ProductsPage extends Component {
  static defaultState = {
    pageLoaded: false,
    product: null, //{}
    productGroups: null, //[]
    groupTitle: '',
    searchableProducts: null, //[]
    productPageView: 'description',
    tagline: ''
  }

  state = ProductsPage.defaultState;

  componentDidMount() {
    this.setResources();
    this.setSearchableProducts();

    setTimeout(() => {
      this.props.considerShowingSubscribePopup({pathname: this.props.location.pathname});
    }, 3000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);

      var preservedKeys = ['searchableProducts'];

      if (this.id) preservedKeys.push('productPageView');
      if ((this.groupMode === 'all' || this.searchTerm) && !prevProps.match.params.id) preservedKeys.push('pageLoaded');

      this.setState(_.omit(ProductsPage.defaultState, preservedKeys), this.setResources);
    }

    document.title = this.state.product ? this.state.product.title : `primary objects`;
  }

  async setResources() {
    var {groupMode, groupKey, id, removeDrawings} = this;
    var canViewTradePrice =  _.get(this.props.session, 'user.id') !== undefined;

    if (id) {
      var {products, groupTitle} = await utilities.getFullProductsData({ids: id, groupKey, groupMode, canViewTradePrice});

      var product = products[0];

      if (product) this.renderStructuredDataFor({product});

      this.setState({product, groupTitle, pageLoaded: true});
    }
    else {
      var {productGroups, groupTitle, tagline} = await this.getProductGroups({groupKey, groupMode}); //eslint-disable-line
      this.setState({productGroups, groupTitle, tagline}, () => {
        this.setState({productGroups: removeDrawings({productGroups})});

        setTimeout(() => this.setState({pageLoaded: true}), 1000);
      });
    }

    setTimeout(() => {
      this.props.considerShowingSubscribePopup({pathname: this.props.location.pathname});
    }, 3000);
  }

  removeDrawings = ({productGroups}) => _.map(productGroups, productGroup => (
    {...productGroup, media: _.orderBy(_.reject(productGroup.media, {subjectType: 'drawing'}), 'rank', 'desc')}
  ));

  async getProductGroups({groupKey, groupMode}) {
    var {breakoutHeterogeneous} = this;
    var productGroups = [];
    var bottomLevelProductCategories;
    var useProductTags = _.includes(['activity', 'space'], groupMode);
    var groupTitle, tagline;

    if (groupMode === 'all') {
      groupTitle = 'full collection';
    }
    else if (useProductTags) {
      if (groupKey) {
        var middleLevelProductCategories = await lib.api.get('middleLevelProductCategories', {
          where: {parentCategoryId: 11}, order: ['rank'],
          include: {
            bottomLevelProductCategories: {
              order: ['rank', 'id'],
              include: {
                media: {...utilities.defaultMediaParams, include: {productTags: {}}},
                products: {
                  ...utilities.defaultIndexProductParams,
                  include: {media: utilities.defaultMediaParams}
                },
                productTags: {}
              }
            }
          }
        });

        bottomLevelProductCategories = _.flatMap(middleLevelProductCategories, 'bottomLevelProductCategories');

        productGroups = _.uniqBy(_.filter(bottomLevelProductCategories, ({productTags, products}) => (
          products.length > 0 && _.some(productTags, {type: groupMode, key: groupKey})
        )), 'title');

        _.forEach(productGroups, productGroup => {
          var filteredMedia = _.filter(productGroup.media, ({productTags}) => _.includes(_.map(productTags, 'key'), groupKey));

          if (filteredMedia.length > 0) productGroup.media = filteredMedia;
        });

        if (productGroups[0]) groupTitle = _.find(productGroups[0].productTags, {type: groupMode, key: groupKey}).title;
      }
      else {
        productGroups = await lib.api.get('productTags', {
          where: {type: groupMode}, order: ['rank', 'id'],
          include: {media: {...utilities.defaultMediaParams, include: {products: {...utilities.defaultIndexProductParams, fields: ['id']}}}}
        });

        productGroups = _.map(productGroups, productGroup => {
          return {...productGroup, media: _.filter(productGroup.media, medium => !(medium.products.length === 1 && medium.products[0].isDiscontinued))};
        });

        productGroups = _.filter(productGroups, productGroup => {
          return productGroup.media.length > 0;
        });
      }
    }
    else {
      if (groupKey) {
        var middleLevelProductCategory = await lib.api.get('middleLevelProductCategory', {
          where: {key: groupKey},
          order: ['rank', 'id'],
          include: {
            bottomLevelProductCategories: {
              order: ['rank', 'id'],
              include: {media: utilities.defaultMediaParams, products: {...utilities.defaultIndexProductParams, include: {media: utilities.defaultMediaParams}}}
            }
          }
        });

        if (middleLevelProductCategory) {
          productGroups = _.filter(middleLevelProductCategory.bottomLevelProductCategories, bottomLevelProductCategories => bottomLevelProductCategories.products.length > 0);

          ({tagline, title: groupTitle} = middleLevelProductCategory);
        }
      }
      else  {
        productGroups = await lib.api.get('middleLevelProductCategories', {
          order: ['rank'],
          where: {parentCategoryId: 11},
          include: {media: {...utilities.defaultMediaParams, include: {products: {fields: ['id']}}}}
        });

        productGroups = _.map(productGroups, productGroup => {
          return {...productGroup, media: _.filter(productGroup.media, medium => !(medium.products.length === 1 && medium.products[0].isDiscontinued))};
        });

        productGroups = _.filter(productGroups, productGroup => {
          return productGroup.media.length > 0;
        });
      }
    }

    if (groupKey) productGroups = breakoutHeterogeneous({productGroups});

    return {productGroups, groupTitle, tagline};
  }

  breakoutHeterogeneous({productGroups}) {
    var newGroups = [];

    _.forEach(productGroups, ({isHeterogeneous, id, key, title, media, products, parentCategoryTitle, productTags}) => {
      if (isHeterogeneous) {
        _.forEach(products, product => newGroups.push({...product, parentCategoryTitle, productTags}));
      }
      else {
        newGroups.push({key, id, title, media, products, parentCategoryTitle, productTags});
      }
    });

    return newGroups;
  }

  async setSearchableProducts() {
    var middleLevelProductCategories = await lib.api.get('middleLevelProductCategories', {
      where: {parentCategoryId: 11}, order: ['rank'],
      include: {
        bottomLevelProductCategories: {
          where: {}, order: ['rank', 'id'],
          include: {media: utilities.defaultMediaParams, productTags: {}, products: {...utilities.defaultShowProductParams, include: {media: utilities.defaultMediaParams}}}
        }
      }
    });

    var searchableProducts = _.flatMap(middleLevelProductCategories, middleCategory => (
      _.map(middleCategory.bottomLevelProductCategories, bottomCategory  => ({...bottomCategory, parentCategoryTitle: middleCategory.title}))
    ));

    searchableProducts = _.filter(searchableProducts, searchableProduct => searchableProduct.products.length > 0);

    searchableProducts = this.breakoutHeterogeneous({productGroups: searchableProducts});

    searchableProducts = this.removeDrawings({productGroups: searchableProducts});

    this.setState({searchableProducts});
  }

  handleSearchInput = ({value: searchTerm}) => {
    var currentUrl = _.get(this.props.history, 'location.pathname');
    var query = searchTerm ? `?searchTerm=${searchTerm}` : '';

    this.props.history.push(`${currentUrl}${query}`);
  }

  switchProductPageView = () => {
    this.setState(oldState => ({productPageView: oldState.productPageView === 'description' ? 'options' : 'description'}));
  }

  renderStructuredDataFor({product}) {
    var structuredDataScript = document.getElementById('structured-data');
    var {dimensionsData} = lib.dimensions.dimensionDataSetsFor({product})[0];

    var structuredDataContent = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": _.startCase(product.title),
      "description": `${product.description}`,
      "image": product.media[0].url.split("?")[0],
      "brand": {
        "@type": "Brand",
        "name": "Henrybuilt"
      },
      //TODO map over product.materialAssociations to extract material titles
      // "material": ["Wood", "Brass", "Fabric", "Aluminum", "HPL"],
      "offers": {
        "@type": "Offer",
        "url": window.location.href,
        "price": `${Number(product.price.replace(/[^0-9.]/g, ""))}`, //TODO is this accurate?
        "priceCurrency": "USD",
        "availability": "https://schema.org/InStock",
        "itemCondition": "https://schema.org/NewCondition"
      },
      "additionalProperty": [
        _.map(dimensionsData, ({constraintDescription, title}) => {
          return {
            "@type": "PropertyValue",
            "name": _.upperFirst(title),
            "value": constraintDescription
          };
        })
      ]
    };

    if (structuredDataScript) {
      structuredDataScript.textContent = JSON.stringify(structuredDataContent);
    }
    else {
      structuredDataScript = document.createElement('script');
      structuredDataScript.id = "structured-data";
      structuredDataScript.type = "application/ld+json";
      structuredDataScript.textContent = JSON.stringify(structuredDataContent);
      document.head.appendChild(structuredDataScript);
    }
  }

  get searchTerm() {
    return _.get(queryString.parse(window.location.search), 'searchTerm');
  }

  get groupMode() {
    return _.get(this.props, `match.params.groupMode`, 'type');
  }

  get groupKey() {
    return _.get(this.props, `match.params.groupKey`);
  }

  get id() {
    return _.get(this.props, `match.params.id`);
  }

  render () {
    var {id, groupMode, groupKey, searchTerm, handleSearchInput, switchProductPageView} = this;
    var {product, productGroups, groupTitle, searchableProducts, pageLoaded, productPageView, tagline} = this.state;
    var {history} = this.props;

    if (searchTerm) {
      var matchGroups = [[], [], [], []];

      var titleFromArray = array => _.join(_.map(array, 'title'), ' ');
      var productAdded = false;

      _.forEach(searchableProducts, searchableProduct => {
        var {title, parentCategoryTitle, products, productTags, keywords} = searchableProduct;
        var isSearchMatch;
        productAdded = false;

        _.forEach([
          {titleKey: 'productTitle', string: `${title} ${titleFromArray(products)}`},
          {titleKey: 'categoryTitle', string: parentCategoryTitle},
          {titleKey: 'tags', string: titleFromArray(productTags)},
          {titleKey: 'keywords', string: products ? _.join(_.map(products, 'keywords'), ', ') : keywords}
        ], ({titleKey, string}, index) => {
          if (titleKey !== 'keywords') {
            isSearchMatch = lib.string.isSearchMatch({title: string, input: searchTerm, distance: 1, matchPlurals: true});
          }
          else {
            isSearchMatch = _.includes(_.lowerCase(string.split(', ')), _.lowerCase(searchTerm));
          }

          if (!productAdded && isSearchMatch) {
            matchGroups[index].push(searchableProduct);

            productAdded = true;
          }
        });
      });

      productGroups = _.flatten(matchGroups);
    }
    else if (groupMode === 'all') {
      groupKey = 'all';
      productGroups = searchableProducts;
    }

    handleSearchInput = _.debounce(handleSearchInput, 500);

    var props = {id, product, productGroups, groupMode, groupKey, groupTitle, searchTerm, tagline, handleSearchInput};

    var ProductsDisplay = product ? <ProductsShow {...{product, productPageView, switchProductPageView, history}}/> : <ProductsIndex {...props}/>;

    var viewKey = 'groups';
    if (groupKey || searchTerm) viewKey = 'products';
    if (product) viewKey = 'product';

    return ((!_.isEmpty(productGroups) || product || !pageLoaded || searchTerm) ? (
         <div {...className([`products-content products-content-${viewKey}`, pageLoaded && 'page-loaded'])}>
          <div className='products-main-content-outer'>
            <div {...className(['products-main-content-inner', `${viewKey}-view`])}>
              {pageLoaded && <ProductsHeader {...props} key={groupKey || groupMode}/>}
              {!id && <div className='tagline'>{(!searchTerm && tagline) || 'Intelligent Luxury, One Piece at a Time.'}</div>}
              {ProductsDisplay}
            </div>
            {!pageLoaded && <div className='loading-symbol'/>}
          </div>
          <Sidebar key={groupKey || groupMode} {...{...props, pageLoaded}}/>
        </div>
      ) : (
        <PageNotFound/>
      )
    );
  }
}

ProductsPage = connect({mapState: ['session']})(ProductsPage);

export default ProductsPage;
